const Logos = [`
      ▄▄▄▄     ▄▄▄▄     ▄▄▄▄
▄▄▄▄▄▄█  █▄▄▄▄▄█  █▄▄▄▄▄█  █
█__ --█  █__ --█    ◄█  -  █
█▄▄▄▄▄█▄▄█▄▄▄▄▄█▄▄█▄▄█▄▄▄▄▄█
`,
`
        ▄▄▄▄         ▄▄▄▄       ▄▄▄▄
▄▄▄▄▄▄▄ █  █ ▄▄▄▄▄▄▄ █  █▄▄▄ ▄▄▄█  █
█__ --█ █  █ █__ --█ █    ◄█ █  -  █
█▄▄▄▄▄█ █▄▄█ █▄▄▄▄▄█ █▄▄█▄▄█ █▄▄▄▄▄█
`];

export default Logos;